<template>
  <v-container class="cities-list-container">
    <v-text-field
      label="Trova il tuo comune"
      placeholder="Città"
      dense
      outlined
      @input="filterCities"
    ></v-text-field>
    <div class="list" :style="`height: ${height}px`">
      <v-list-item dense v-for="city in citiesFiltered" :key="city.id">
        {{ city.name }}
      </v-list-item>
    </div>
  </v-container>
</template>
<style lang="scss">
.cities-list-container {
  .list {
    overflow-y: scroll;
    display: block;
  }
}
</style>
<script>
import axios from "~/plugins/axios";

export default {
  name: "CitiesList",
  props: {
    height: {
      type: String
    }
  },
  data: () => ({
    cities: [],
    citiesFiltered: []
  }),
  methods: {
    async fetchCities() {
      return axios.get("/ebsn/api/geo/homedelivery-list", {}).then(data => {
        return data.data.data;
      });
    },
    async loadCities() {
      const data = await this.fetchCities();

      // eslint-disable-next-line no-unused-vars
      let provinces = [];
      for (const country of data.countries) {
        if (country.countryId === 1) {
          provinces = country.provinces;
        }
      }

      let normalized = [];

      for (const province of provinces) {
        for (const city of province.cities) {
          city.cityName = city.cityName.concat(
            " (" + province.provinceName + ")"
          );
          city.province = province.provinceName;
          normalized.push({ name: city.cityName, id: city.cityId });
        }
      }

      this.cities = normalized;
      this.citiesFiltered = normalized;
    },
    filterCities(criteria) {
      if (criteria.length > 0) {
        const regex = new RegExp(`^((.*)${criteria}(.*))$`, "gi");
        this.citiesFiltered = this.cities.filter(city => regex.test(city.name));
      } else {
        this.citiesFiltered = this.cities;
      }
    }
  },
  mounted() {
    this.loadCities();
  }
};
</script>
